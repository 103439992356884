<template>
  <div class="balance">
    <div class="title">余额管理</div>
    <!-- <p class="moneyAcount">￥43,903.30</p> -->
    <div class="container">
      <a-card style="margin-bottom: 12px" :loading="spinLoading">
        <a-row :gutter="48" type="flex">
          <a-col :span="14" class="border">
            <p class="moneyTitle">账户余额</p>
            <div class="l-flex-allcenter money-info">
            <div class="money-handle">
              <template v-if="info.balanceStatus === 0">
                <div class="bank-nobind text-center">
                  <a-popconfirm
                    v-if="info.balanceStatus === 0"
                    title="请确定是否开通"
                    @confirm="openPay"
                  >
                    <a-button  class="balance-btn noOpen" :loading="subAccountLoading">开通余额支付</a-button>
                  </a-popconfirm>
                </div>
              </template>
              <template v-else>
                <p class="text-center money-note" style="padding: 0">余额：<span>￥{{info.totalBalance}}</span></p>
              </template>
            </div>
            <a-space class="money-note" direction="vertical" :size="20"  v-if="info.balanceStatus == 1 || info.balanceStatus == 2">
              <p>可提现：<span class="moneyAcount">￥{{info.canDrawCashBalance}}</span></p>
              <div class="text-center">
                <a-space>
                  <a-button type="primary" @click="visible2 = true;" v-if="info.balanceStatus === 2">提现</a-button>
                  <a-button type="primary" v-if="info.balanceStatus === 1 || info.balanceStatus === 2" @click="paInfo">余额</a-button>
                </a-space>
              </div>  
            </a-space>
            </div>
          </a-col>
          <a-col :span="10">
            <p class="moneyTitle">提现银行卡</p>
            <!-- 已绑卡 -->
            <template v-if="info.balanceStatus === 2">
              <div class="bank-card">
                <div class="icon">
                  <img :src="iconBank(info.bankName)" alt="">
                </div>
                <p><b>{{info.bankName}}</b></p>
                <p class="bank-code">{{info.bankCodeNo}}</p>
                <p class="bank-num">{{str(info.bankAccount)}}</p>
              </div>
            </template>
            <!-- 未绑卡 -->
            <template v-else>
              <div class="bank-nobind text-center" v-if="info.balanceStatus === 1 && !info.enableContinueBindCard">
                <a-button type="primary" class="balance-btn noBind" @click="$refs.entityBind.toBind()">提现绑卡</a-button>
              </div>
              <!-- 未鉴权 -->
              <a-space v-if="info.balanceStatus === 1 && info.enableContinueBindCard"  class="space-nobind text-center" direction="vertical">
                <p>{{info.continueBindBankInfo.bankName}} ---- {{info.continueBindBankInfo.bankAccount}}</p>
                <a-space>
                  <a-button class="balance-btn noBind" @click="$refs.entityBind.toAAA(true)">继续鉴权</a-button>
                  <a-button type="primary" class="balance-btn noBind" @click="$refs.entityBind.toBind()">重新绑卡</a-button>
                </a-space>
              </a-space>
            </template>
          </a-col>
        </a-row>
      </a-card>
      <a-card :tab-list="tabList" :active-tab-key="tabKey" @tabChange="tabChange">
        <div v-show="tabKey === 'tab1'">
          <a-space :size="20" class="space-b" v-if="false">
            <span>
              交易时间：
              <a-space>
                <a-date-picker :value="dealForm.startDate" @change="dealForm.startDate = $event ? $event.format('YYYY-MM-DD') : undefined" placeholder="开始日期" :disabled-date="disabledStartDate" :disabled="dealForm.timeType === 0">
                </a-date-picker>
                -
                <a-date-picker :value="dealForm.endDate" @change="dealForm.endDate = $event ? $event.format('YYYY-MM-DD') : undefined" placeholder="结束日期" :disabled-date="disabledEndDate" :disabled="dealForm.timeType === 0">
                </a-date-picker>
              </a-space>
            </span>
            <span>流向：
              <a-select style="width: 120px" v-model="dealForm.queryFlag">
                <a-select-option :key="1"> 全部 </a-select-option>
                <a-select-option :key="2"> 转出 </a-select-option>
                <a-select-option :key="3"> 转入 </a-select-option>
              </a-select>
            </span>
            <span>时间类型：
              <a-select style="width: 120px" v-model="dealForm.timeType">
                <a-select-option :key="0"> 今天 </a-select-option>
                <a-select-option :key="1"> 历史 </a-select-option>
              </a-select>
            </span>
            <a-button type="primary" @click="dealForm.pageNum = 1;suppliertradeList()">查询</a-button>
          </a-space>

          <a-table :rowKey="(record,index)=>{return index}" :columns="columns" size="middle" :loading="dealForm.loading" :data-source="dealForm.data" :pagination="{
              size: 'middle',
              total: dealForm.total,
              current: dealForm.pageNum,
              pageSize: dealForm.pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['5', '10', '20', '50', '100'],
              showTotal: (total) => `共有 ${dealForm.total} 条数据`,
              showQuickJumper: true,
              onShowSizeChange: (current, pageSize) => {
                dealForm.pageSize = pageSize
                dealForm.pageNum = 1
                this.suppliertradeList()
              },
              onChange: (pageNum) => {
                dealForm.pageNum = pageNum
                this.suppliertradeList()
              }
            }">
            <span slot="tradeType" slot-scope="text">{{ text | tradeType }}</span>
            <span slot="tradeStatus" slot-scope="text">{{ text | tradeStatus }}</span>
            <a-tooltip placement="top" slot="remark" slot-scope="text"><template slot="title">{{ text }}</template>{{text}}</a-tooltip>
            <span slot="orderId" slot-scope="text,record">
              <router-link :to="`/order-details?id=${record.orderId}`">{{ text }}</router-link>
            </span>
          </a-table>
        </div>
      </a-card>
      <!-- 余额提现 -->
      <a-modal :width="680" title="余额提现" v-model="visible2" :visible="visible2" :closable="false" :centered="true" @ok="withdraw" :confirm-loading="confirmLoading">
        <a-row class="count" align="middle" type="flex">
          <a-col :span="24">
            <p class="hint">
              <a-icon type="warning" />余额提现只能转向绑定的银行卡，不可使用其他银行账号
            </p>
          </a-col>
          <a-col :span="3">账号</a-col>
          <a-col :span="20">
            <a-space :size="32">
              <span>{{info.bankName}}</span>
              <span>{{info.bankAccount}}</span>
            </a-space>
          </a-col>
          <a-col :span="3">可提现金额</a-col>
          <a-col :span="21" class="amount-modal">￥{{info.canDrawCashBalance}}</a-col>
          <a-col :span="24">
            <p class="hint">
              <a-icon type="warning" />请选择已完成交易但未提现的订单进行提现操作
            </p>
          </a-col>
          <a-col :span="24">
            <a-table rowKey="orderId" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columnsOrder" size="middle" :data-source="withdrawForm.data" :loading="withdrawForm.loading" :pagination="{
              size: 'middle',
              total: withdrawForm.total,
              current: withdrawForm.pageNum,
              pageSize: withdrawForm.pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['5', '10', '50', '100'],
              showTotal: (total) => `共有 ${withdrawForm.total} 条数据`,
              showQuickJumper: true,
              onShowSizeChange: (current, pageSize) => {
                withdrawForm.pageSize = pageSize
                withdrawForm.pageNum = 1
                this.withdrawList()
              },
              onChange: (pageNum) => {
                withdrawForm.pageNum = pageNum
                this.withdrawList()
              }
            }">
              <span :style="{color: statusColor(text)}" slot="status" slot-scope="text" >{{text | statusType}}</span>
            </a-table>
          </a-col>
          <a-col :span="4"><b>本次提现金额</b></a-col>
          <a-col :span="20">
            <a-space :size="32">
              <span><b><span class="amount">{{amount.amount.toFixed(2)}}</span> 元</b></span>
              <span><b>其中对应税款 <span class="amount-s">{{amount.tax.toFixed(2)}}</span> 元</b></span>
            </a-space>
          </a-col>
          <a-col :span="3">支付密码</a-col>
          <a-col :span="21" class="l-flex-aligncenter">
            <payInput ref="payInput" @pwd="withdrawForm.password = $event"/>
            <!-- <span style="margin-left: 24px">请输入6位数余额支付密码</span> -->
          </a-col>
        </a-row>
      </a-modal>
    </div>
    <entityBindStep class="entitybind" ref="entityBind" isSeller @ok="paInfo();" :enableContinueBindCard="info.enableContinueBindCard"/>
    <open-pay ref="openSuccess" @ok="$refs.entityBind.toBind()"></open-pay>
    <payPwd ref="payPwd" isSeller/>
  </div>
</template>
<script>
import {paInfo, openSubAccountSupplier, withdrawList, withdraw, suppliertradeList} from '@/api/pa'
import entityBindStep from '@/components/paBind/entityBindStep'
import openPay from '@/components/paBind/openPay'
import payPwd from '@/components/payPwd'
import payInput from '@/components/payInput'
import {paTallyType,tradeType,tradeStatus} from '@/utils/constArr'
import md5 from 'js-md5'
import moment from 'moment'
const columns = [
  {
    title: '创建时间',
    dataIndex: 'createdTime'
  },
  {
    title: '订单ID',
    width: 60,
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    title: '支付编号',
    dataIndex: 'paymentNo',
  },
  {
    title: '出金账号',
    dataIndex: 'outAccountNum',
  },
  {
    title: '入金账号',
    dataIndex: 'inAccountNum',
  },
  {
    title: '金额',
    dataIndex: 'amount',
    align: 'right'
  },
  {
    title: '类型',
    width: 50,
    dataIndex: 'tradeType',
    scopedSlots: { customRender: 'tradeType' }
  },
  {
    title: '状态',
    dataIndex: 'tradeStatus',
    width: 72,
    scopedSlots: { customRender: 'tradeStatus' }
  },
  {
    title: '备注',
    scopedSlots: { customRender: 'remark' },
    dataIndex: 'remark',
    ellipsis: true
  }
]
const columnsWithdrawRecord = [
  {
    title: '订单编号',
    dataIndex: 'orderId'
  },
  {
    title: '支付编号',
    dataIndex: 'paymentNo',
  },
  {
    title: '税款',
    align: 'right',
    dataIndex: 'taxAmount',
  },
  {
    title: '总金额',
    align: 'right',
    dataIndex: 'totalAmount',
  },
  {
    title: '货款',
    align: 'right',
    dataIndex: 'grossAmount',
  },
  {
    title: '状态',
    dataIndex: 'payStatusStr',
  },
  {
    title: '创建时间',
    dataIndex: 'createdTime'
  },
  {
    title: '更新时间',
    dataIndex: 'lastUpdatedTime'
  },
  // {
  //   title: '操作',
  //   dataIndex: 'operation',
  //   scopedSlots: { customRender: 'operation' }
  // }
]
const columnsOrder = [
  {
    title: '订单号',
    dataIndex: 'orderId'
  },
  {
    title: '买家',
    dataIndex: 'purchaserName'
  },
  {
    title: '货款',
    align: 'right',
    dataIndex: 'netAmount',
  },
  {
    title: '税费',
    dataIndex: 'taxAmount',
    align: 'right',
  },
  {
    title: '价税合计',
    dataIndex: 'contractAmount',
    align: 'right',
  },
  // {
  //   title: '提现状态',
  //   dataIndex: 'status',
  //   scopedSlots: { customRender: 'status' }
  // }
]

export default {
  components: {
    payInput,
    entityBindStep,
    openPay,
    payPwd
  },
  data() {
    return {
      columns,
      columnsWithdrawRecord,
      columnsOrder,
      subAccountLoading: false,
      confirmLoading: false,
      spinLoading: true,
      tabList: [
        {
          key: 'tab1',
          tab: '交易明细',
        }
      ],
      tabKey: 'tab1',
      dealForm: {
        pageNum: 1,
        pageSize: 5,
        total: 0,
        supplierId: this.$route.query.entityId,
        data: [],
        queryFlag: 1,
        timeType: 1,
        startDate: moment().add(-1,'month').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        loading: false
      },
      withdrawForm: {
        pageNum: 1,
        pageSize: 5,
        total: 0,
        loading: false,
        supplierId: this.$route.query.entityId,
        data: [],
        password: ''
      },
      visible2: false,
      info: {
        canDrawCashBalance: 0,
        balanceStatus: 0,
        bankAccount: undefined,
        bankName: undefined,
        phone: undefined,
        enableContinueBindCard: false,
        bankCodeNo: undefined,
        continueBindBankInfo: {},
        totalBalance: 0,
      },
      selectedRowKeys: [],
      bankIconList: [
          {name: '工商银行', imgUrl: require("../../assets/images/bank/bankGS.png")},
          {name: '光大银行', imgUrl: require("../../assets/images/bank/bankGD.png")},
          {name: '华夏银行', imgUrl: require("../../assets/images/bank/bankHX.png")},
          {name: '建设银行', imgUrl: require("../../assets/images/bank/bankJS.png")},
          {name: '交通银行', imgUrl: require("../../assets/images/bank/bankJT.png")},
          {name: '农业银行', imgUrl: require("../../assets/images/bank/bankNY.png")},
          {name: '平安银行', imgUrl: require("../../assets/images/bank/bankPA.png")},
          {name: '浦发银行', imgUrl: require("../../assets/images/bank/bankPF.png")},
          {name: '人民银行', imgUrl: require("../../assets/images/bank/bankRM.png")},
          {name: '兴业银行', imgUrl: require("../../assets/images/bank/bankXY.png")},
          {name: '招商银行', imgUrl: require("../../assets/images/bank/bankZS.png")},
          {name: '中信银行', imgUrl: require("../../assets/images/bank/bankZX.png")},
      ],
    }
  },
  created() {
    this.paInfo()
    this.suppliertradeList()
  },
  filters: {
    type: (val) => paTallyType[val],
    flag(val) {
      const obj = {
        '01': '提现',
        '02': '清分'
      }
      return obj[val]
    },
    statusType(val) {
      const obj = {
        0: '未提现',
        1: '税款未提现',
        2: '货款未提现',
      }
      return obj[val]
    },
    tradeType: (val) => tradeType[val] || '',
    tradeStatus: (val) => tradeStatus[val] || ''
  },
  watch: {
    visible2(newVal) {
      if (newVal) {
        this.withdrawForm.pageNum = 1
        this.withdrawForm.pageSize = 5
        this.withdrawForm.password = ''
        this.selectedRowKeys = []
        this.withdrawList()
      } else {
        this.$refs.payInput.reset()
      }
    },
  },
  computed: {
    amount() {
      let amount = {
        amount: 0,
        tax: 0
      }
      this.selectedRowKeys.forEach(item => {
        const i = this.withdrawForm.data.find(i => i.orderId === item)
        amount.amount = amount.amount + i.contractAmount
        amount.tax = amount.tax + i.taxAmount
        // if (i.status == 0) {
        //   amount.amount = amount.amount + i.contractAmount
        //   amount.tax = amount.tax + i.taxAmount
        // } else {
        //   if (i.status === 1) {
        //     amount.amount = amount.amount + i.taxAmount
        //     amount.tax = amount.tax + i.taxAmount
        //   } else if (i.status === 2) {
        //     amount.amount = amount.amount + i.netAmount
        //   }
        // }
      })
      console.log(amount);
      return amount
    }
  },
  methods: {
    statusColor(val) {
      const obj = {
        0: '#52c41a',
        1: '#fa8c16',
        2: '#722ed1',
      }
      return obj[val]
    },
    paInfo () {
      this.spinLoading = true
      paInfo({type: 'S', entityId: this.$route.query.entityId}).then(res => {
        this.spinLoading = false
        if (res.code === 200) {
          const {canDrawCashBalance,balanceStatus,bankAccount,bankName,phone,enableContinueBindCard,continueBindBankInfo,bankCodeNo, totalBalance} = res.data
          this.info = {canDrawCashBalance,balanceStatus,bankAccount,bankName,phone,enableContinueBindCard,continueBindBankInfo,bankCodeNo, totalBalance}
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 开通平安付
    openPay () {
      this.subAccountLoading = true
      openSubAccountSupplier({supplierId: this.$route.query.entityId}).then(res => {
      this.subAccountLoading = false
      if (res.code ===200) {
          this.paInfo()
          this.$refs.openSuccess.isPay = true
      } else {
        this.$message.error(res.message)
      }
      })
    },
    getlist() { },
    iconBank(val) {
      let url = require("../../assets/images/bank/all.png")
      this.bankIconList.forEach(item => {
          if (val && val.indexOf(item.name) !== -1) {
              url = item.imgUrl
          }
      })
      return url
    },
    str(val) {
      console.log('222',val);
        if (!val) return
        const suffix = val.substring(val.length - 4)
        const prefix = val.substring(0,5)
        return prefix + '******' + suffix
    },
    tabChange (e) {
      this.tabKey = e
      if (e === 'tab1') {
        this.suppliertradeList()
      }
    },
    withdrawList () {
      this.withdrawForm.loading = true
      this.selectedRowKeys = []
      withdrawList(this.withdrawForm).then(res => {
        this.withdrawForm.loading = false
        if (res.code === 200) {
          this.withdrawForm.data = res.data.list
          this.withdrawForm.total = res.data.total
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 提现
    withdraw() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.error('请先勾选订单')
        return
      }
      if (this.withdrawForm.password.length !== 6) {
        this.$message.error('请输入正确的支付密码')
        return
      }
      this.confirmLoading = true
      withdraw({password: md5(this.withdrawForm.password), orderIds: this.selectedRowKeys, supplierId: this.$route.query.entityId}).then(res => {
        this.confirmLoading = false
        if (res.code === 200) {
          this.visible2 = false
          this.$message.success(res.message)
          this.suppliertradeList()
          this.paInfo()
        } else {
          this.$error({
            title: '提现失败',
            content: res.message
          });
        }
      })
    },
    onSelectChange(selectedRowKeys) {
      console.log('选中的值', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
      // this.selectedRowKeys = [...this.selectedRowKeys, ...selectedRowKeys].filter(function(value,index,self){
      //     return self.indexOf(value) === index;
      // })
    },
    // 收益明细
    suppliertradeList () {
      const form = Object.assign({}, this.dealForm)
      if (this.dealForm.timeType === 0) {
        form.startDate = undefined
        form.endDate = undefined
      } else {
        if (form.endDate && !form.startDate) {
          this.$message.error('请选择开始日期')
          return
        } else if (form.startDate && !form.endDate) {
          this.$message.error('请选择结束日期')
          return
        }
      }
      this.dealForm.loading = true
      suppliertradeList(form).then(res => {
        this.dealForm.loading = false
        if (res.code ===200) {
          this.dealForm.data = res.data.list
          this.dealForm.total = res.data.total
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 收益明细时间选择器
    pickerChange(m, arr) {
      this.dealForm.startDate = arr[0] || undefined
      this.dealForm.endDate = arr[1] || undefined
    },
    disabledDate (current) {
      return current > moment()
    },
    disabledStartDate (current) {
      if (this.dealForm.endDate) {
        return current > moment(this.dealForm.endDate) || current > moment()
      }
      return current > moment()
    },
    disabledEndDate (current) {
      if (this.dealForm.startDate) {
        return current < moment(this.dealForm.startDate) || current > moment()
      }
      return current > moment()
    },
  }
}
</script>
<style lang="less" scoped>
.balance {
  .title {
    background: #fff;
    font-weight: 650;
    font-style: normal;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
    padding: 20px;
  }
  .container {
    padding: 16px 0 0 12px;
  }
  .moneyTitle {
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    color: rgba(0, 0, 0);
    line-height: 22px;
  }
  .money-info {
    margin-top: 16px;
  }
  .money-note {
    font-size: 16px;
    padding-left: 60px;
    color: rgba(0, 0, 0, 0.75);
    span {
      color: rgba(253, 89, 0);
      font-size: 20px;
    }
  }
  .money-handle {
    min-width: 140px;
  }
  .money-row{
    padding-top: 20px;
  }
  .moneyAcount {
    color: rgba(253, 89, 0);
    font-size: 30px !important;
    line-height: 1;
  }
  .text-center {
    text-align: center;
  }
  .record {
    padding: 10px 0 0 20px;
  }
  .recordMoney {
    display: inline-block;
    width: 100px;
    text-align: right;
  }
  .pt10 {
    padding-top: 10px;
  }

  .O6D {
    color: #0000006d;
  }
  .OA5 {
    color: #000000a5;
  }
}
.hint {
  line-height: initial;
  text-align: left;
  padding: 12px;
  border-radius: 5px;
  background-color: @primary;
  color: #fff;
  i {
    margin-right: 8px;
  }
}
.count {
  line-height: 38px;
  color: #666666;
  /deep/ .ant-col {
    margin-bottom: 12px;
  }
}
.fs16 {
  font-size: 16px;
}
.fw700 {
  font-weight: 700;
  color: #666;
}
.pt20 {
  padding-top: 20px;
}
.pl20 {
  padding-left: 20px;
}
.pl50 {
  padding-left: 50px;
}

/deep/.ant-modal-title {
  font-size: 18px;
}
/deep/ .ant-modal-body {
  padding: 24px 32px;
}
/deep/.ant-modal-footer {
  border: none;
  padding-bottom: 42px;
  padding-top: 0;
  button {
    width: 80px;
  }
}
/deep/.ant-modal-footer {
  text-align: center;
}
.border {
  border-right: 1px solid #e1c0ff;
}
.bank-card {
  margin: 0 auto;
  margin-top: 16px;
  max-width: 300px;
  height: 111px;
  background-color: #5bb0ff;
  border-radius: 5px;
  padding: 16px 0;
  padding-left: 54px;
  position: relative;
  color: #fff;
  .bank-code {
    font-size: 12px;
    margin-bottom: 16px;
  }
  .bank-num {
    font-size: 16px;
  }
  .icon {
    position: absolute;
    left: 0;
    width: 42px;
    height: 42px;
    background: #bfeef7;
    // border: 1px solid rgba(0, 0, 0, 0.8);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    img {
      width: 42px;
      height: 42px;
    }
  }
  .select {
    position: absolute;
    right: 5px;
    bottom: 5px;
    i {
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        color: @error;
      }
    }
  }
  .select-check {
    color: @error;
  }
}
.space-b {
  margin-bottom: 24px;
}
.amount {
    font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 28px;
    color: #D9001B;
}
.amount-s {
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  color: #D9001B;
}
.space-nobind {
  padding: 32px 0;
  width: 100%;
}
.bank-nobind {
  padding: 32px 0;
  width: 100%;
}
.amount-modal {
  font-size: 20px;
  color: rgba(253, 89, 0);
}
</style>